<template>
  <div class="autocomplete">
    <div class="row align-items-center">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="input-group">
          <input
            class="form-control border-right-0 border bg-transparent"
            type="search"
            placeholder="Informe o nome do munícipio"
            @input="onChange"
            v-model="search"
            @keydown.enter="onEnter"
          />
          <!-- @keydown.down="onArrowDown"
            @keydown.up="onArrowUp" -->
          <span class="input-group-append">
            <div class="input-group-text bg-transparent border-left-0">
              <i class="icofont-search-2"></i>
            </div>
          </span>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>

    <div class="row align-items-center">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <ul
          id="autocomplete-results"
          v-show="isOpen"
          class="autocomplete-results"
        >
          <li class="loading" v-if="isLoading">Loading results...</li>
          <li
            v-else
            v-for="(result, i) in results"
            :key="i"
            @click="setResult(result)"
            class="autocomplete-result"
            :class="{ 'is-active': i === arrowCounter }"
          >
            {{ result }}
          </li>
        </ul>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchAutocomplete",
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      results: [],
      search: "",
      isLoading: false,
      arrowCounter: -1,
    };
  },
  watch: {
    items: function (value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value;
        this.isLoading = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    setResult(result) {
      this.search = result;
      this.isOpen = false;

      // if(jQuery(event.target).attr('data-ativo') == "N"){
      //   jQuery("#solicitar_legislacao_text").html(jQuery("#ds_search").val());
      //   jQuery("#btn_modal").click();
      //   jQuery("#list_municipio").hide();
      // }else{
      //   window.location.href = "visualizarLegislacao.php?cdMunicipio=" + jQuery(event.target).attr('data-cd');
      // }

      // console.log('click');
      // console.log(this.items);
      var xxxss = Object.fromEntries(Object.entries(this.items).filter(([key, value]) => {
        //if(value.toString().toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1){
        if(value.toString().toLowerCase() === this.search.toString().toLowerCase()){
          return key;
        }
      }));

      xxxss = (Object.keys(xxxss))[0];

      if(window.dataMunicipiosArrayKeys[xxxss] != undefined){
        window.location.href = "visualizarLegislacao.php?cdMunicipio=" + window.dataMunicipiosArrayKeys[xxxss].replace('_', '');
      }

      // var xxx = this.items.filter((item, index) => {
      //   if(item.toString().toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1){
      //     return index;
      //   }
      //   // return item.toString().toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1;
      // });
      // console.log(xxx);
      // alert('Abrir link...' + xxx);
    },
    filterResults() {
      this.results = this.items.filter((item) => {
        return item.toString().toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1;
      });
    },
    onChange() {
      this.$emit("input", this.search);

      if (this.isAsync) {
        this.isLoading = true;
      } else {
        this.filterResults();
        this.isOpen = true;
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.search = this.results[this.arrowCounter];
      this.isOpen = false;
      this.arrowCounter = -1;
    },
  },
};
</script>

<style>
.autocomplete{
  position: relative;
  color: white;
}

.autocomplete input,
.autocomplete input:focus,
.autocomplete input::placeholder,
.autocomplete .icofont-search-2{
  color: white !important;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 70px;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 2px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: var(--app-default-color-primary-hover);
  color: white;
}
</style>
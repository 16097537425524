<template>

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <h2></h2>
          <ol>
            <li><a href="/">Home</a></li>
            <li><a href="/noticias/">Notícias</a></li>
            <li v-if="dataNoticia.titulo">{{ dataNoticia.titulo }}</li>
          </ol>
        </div>
      </div>
    </section>
    <!-- End Breadcrumbs -->

    <template v-if="$route.params.noticiaAtual">
      <section class="inner-page">
        <div class="container">
          <div class="section-title">
            <h2>{{ dataNoticia.titulo }}</h2>
              <div class="social-links">
                Compartilhar Notícia:
                <a target="_blank" v-bind:href="`https://twitter.com/intent/tweet?url=${this.fullURL}${dataNoticia.url_unico}`">
                    <i class="icofont-twitter"></i>
                  </a>
                  <a target="_blank" v-bind:href="`https://www.facebook.com/sharer/sharer.php?u=${this.fullURL}${dataNoticia.url_unico}`">
                    <i class="icofont-facebook"></i>
                  </a>
                  <a target="_blank" v-bind:href="`https://api.whatsapp.com/send?text=${this.fullURL}${dataNoticia.url_unico}`">
                    <i class="icofont-whatsapp"></i>
                  </a>
                  <a target="_blank" v-bind:href="`https://www.linkedin.com/shareArticle?mini=true&url=${this.fullURL}${dataNoticia.url_unico}`">
                    <i class="icofont-linkedin"></i>
                  </a>
              </div>
          </div>

          <div v-html="dataNoticia.conteudo" class="text-justify"></div>

        </div>
      </section> 
    </template>

    <template v-if="!$route.params.noticiaAtual">
      <!-- ======= Notícias Section ======= -->
      <section id="noticias" class="noticias">
        <div class="container">

          <div class="section-title">
            <h2>Notícias de CESPRO</h2>
            <p>Todas as Notícias</p>
          </div>

          <div class="row">
            
            <div v-for="noticia in dataNoticias" :key="noticia.id"
              class="col-lg-3 col-md-6 d-flex align-items-stretch">
              <a v-bind:href="noticia.url_unico">
                <div class="member">
                  <!-- <img src="assets/img/team/team-1.jpg" alt=""> -->
                  <h4>{{ noticia.titulo }}</h4>
                  <!-- <span>Chief Executive Officer</span> -->
                  <p>
                    {{ noticia.resumo }}
                  </p>
                  <div class="social">
                    <a target="_blank" v-bind:href="`https://twitter.com/intent/tweet?url=${this.fullURL}${noticia.url_unico}`">
                      <i class="icofont-twitter"></i>
                    </a>
                    <a target="_blank" v-bind:href="`https://www.facebook.com/sharer/sharer.php?u=${this.fullURL}${noticia.url_unico}`">
                      <i class="icofont-facebook"></i>
                    </a>
                    <a target="_blank" v-bind:href="`https://api.whatsapp.com/send?text=${this.fullURL}${noticia.url_unico}`">
                      <i class="icofont-whatsapp"></i>
                    </a>
                    <a target="_blank" v-bind:href="`https://www.linkedin.com/shareArticle?mini=true&url=${this.fullURL}${noticia.url_unico}`">
                      <i class="icofont-linkedin"></i>
                    </a>
                  </div>
                </div>
              </a>
            </div>

          </div>

          <br />
          <nav aria-label="Navegação de página exemplo">
            <ul class="pagination justify-content-center">
              <!-- <li class="page-item">
                <a class="page-link" href="#" aria-label="Anterior">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Anterior</span>
                </a>
              </li> -->
              <li v-for="pagina in dataPaginacao" :key="pagina.label">
                <a class="page-link" href="#"><span v-html="pagina.label"></span></a>
              </li>
              <!-- <li class="page-item active"><a class="page-link" href="#">1</a></li> -->
              <!-- <li class="page-item">
                <a class="page-link" href="#" aria-label="Próximo">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Próximo</span>
                </a>
              </li> -->
            </ul>
          </nav>

        </div>
      </section><!-- End Notícias Section -->
    </template>

  </main>
  <!-- End #main -->

</template>


<script>

import axios from 'axios';

export default {
  components: {},

  data(){
    return {
      dataNoticia: {},
      dataNoticias: {},
      dataPaginacao: {},
      fullURL: ''
    }
  },
  methods: {
    t() {
      window.geral();
    },

    async getUltimasNoticias(){
      try{
          let response2 = await axios.get('https://preview.tmpcms.cespro.com.br/api/noticias');
          this.dataNoticias = response2.data.noticias.data;
          this.dataPaginacao = response2.data.noticias.links;
          // console.log(response2.data.noticias.data);
      }catch(e){
          // console.log('error');
      }
    },

    async getNoticia(dsNoticia){
      if(dsNoticia === undefined){
        return;
      }
      try{
          let response2 = await axios.get("https://preview.tmpcms.cespro.com.br/api/noticias/show/"+dsNoticia);
          this.dataNoticia = response2.data.noticia;
      }catch(e){
          // console.log('error');
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      // console.log("entire view has been rendered noticia");
      this.fullURL = window.location.origin;
      this.t();
      this.getNoticia(this.$route.params.noticiaAtual);
      this.getUltimasNoticias();
    });
  },
};
</script>

<template>
  

  <!-- ======= Hero Section ======= -->
  <section id="hero">
    <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

      <ol v-if="dataDestaques.length > 1" class="carousel-indicators" id="hero-carousel-indicators"></ol>

      <div class="carousel-inner" role="listbox">

        <div v-for="(destaque, index) in dataDestaques" :key="destaque.id"
          v-bind:class="index === 0?'carousel-item active':'carousel-item'"
          v-bind:style="`background-image: url(${destaque.url_img})`">

          <video v-if="destaque.url_video" autoplay="autoplay" loop="loop" muted="muted" preload="auto">
              <source v-bind:src="destaque.url_video" type="video/mp4">
          </video>

          <div class="carousel-container">
            <div class="container">
              <h2 class="animate__animated animate__fadeInDown">{{ destaque.titulo }}</h2>
              <p class="animate__animated animate__fadeInUp">{{ destaque.resumo }}</p>
              <a v-if="destaque.url != '#'" v-bind:href="destaque.url"
                class="btn-get-started animate__animated animate__fadeInUp scrollto">Saber Mais</a>

              <template v-if="index === 0">
                <SearchAutocomplete :items="dataMunicipiosArray"/>
              </template>
              
            </div>
          </div>
        </div>

      </div>

      <a v-if="dataDestaques.length > 1" class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon icofont-simple-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>

      <a v-if="dataDestaques.length > 1" class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon icofont-simple-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>

    </div>
  </section><!-- End Hero -->

  <main id="main">

    <!-- ======= Featured Services Section ======= -->
    <section id="featured-services" class="featured-services section-bg">
      <br />
      <div class="container">

        <div class="section-title">
          <h2>Acesso Rápido</h2>
        </div>

        <div class="row no-gutters text-center">

          <div v-for="link in dataLinks" :key="link.id" class="col-lg-2 col-md-6">
            <div class="icon-box">
              <div class="icon">
                <i v-bind:class="link.icone"></i>
              </div>
              <h4 class="title">
                <a v-bind:target="link.url.indexOf('://')>0?'_blank':''" v-bind:href="link.url">{{link.descricao}}</a>
              </h4>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Featured Services Section -->

    <!-- ======= Notícias Section ======= -->
    <section id="noticias" class="noticias section-bg">
      <div class="container">

        <div class="section-title">
          <h2>Notícias de CESPRO</h2>
          <p><a href="/noticias">Ver todas Notícias</a></p>
        </div>

        <div class="row">

          <div v-for="noticia in dataNoticias" :key="noticia.id"
           class="col-lg-3 col-md-6 d-flex align-items-stretch">
            <a v-bind:href="noticia.url_unico">
              <div class="member">
                <!-- <img src="assets/img/team/team-1.jpg" alt=""> -->
                <h4>{{ noticia.titulo }}</h4>
                <!-- <span>Chief Executive Officer</span> -->
                <p>
                  {{ noticia.resumo }}
                </p>
                <div class="social">
                  <a target="_blank" v-bind:href="`https://twitter.com/intent/tweet?url=${this.fullURL}${noticia.url_unico}`">
                    <i class="icofont-twitter"></i>
                  </a>
                  <a target="_blank" v-bind:href="`https://www.facebook.com/sharer/sharer.php?u=${this.fullURL}${noticia.url_unico}`">
                    <i class="icofont-facebook"></i>
                  </a>
                  <a target="_blank" v-bind:href="`https://api.whatsapp.com/send?text=${this.fullURL}${noticia.url_unico}`">
                    <i class="icofont-whatsapp"></i>
                  </a>
                  <a target="_blank" v-bind:href="`https://www.linkedin.com/shareArticle?mini=true&url=${this.fullURL}${noticia.url_unico}`">
                    <i class="icofont-linkedin"></i>
                  </a>
                </div>
              </div>
            </a>
          </div>
          
        </div>

      </div>
    </section><!-- End Notícias Section -->

  </main><!-- End #main -->
</template>


<script>
// @ is an alias to /src

import SearchAutocomplete from "@/components/SearchAutocomplete";
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    SearchAutocomplete
  },
  data(){
    return {
      dataNoticias: {},
      dataLinks: {},
      dataDestaques: {},
      dataMunicipios: {},
      dataMunicipiosArray: [],
      fullURL: ''
    }
  },
  methods: {
    t(){
      window.geral();
    },
    async getUltimasNoticias(){
      try{
          let response2 = await axios.get('https://preview.tmpcms.cespro.com.br/api/noticias');
          this.dataNoticias = response2.data.noticias.data;
          // console.log(response2.data.noticias.data);
      }catch(e){
          // console.log('error');
      }
    },
    async getLinks(){
      try{
          let response2 = await axios.get('https://preview.tmpcms.cespro.com.br/api/links');
          this.dataLinks = response2.data.links;
      }catch(e){
          // console.log('error');
      }
    },
    async getDestaques(){
      try{
          let response2 = await axios.get('https://preview.tmpcms.cespro.com.br/api/destaques');
          this.dataDestaques = response2.data.destaques;

          setTimeout(function(){
            window.makeHeroCarousel();
          }, 200);

      }catch(e){
          // console.log('error');
      }
    },
    async getDadosMunicipios(){
      try{
          let response2 = await axios.get('https://preview.tmpcms.cespro.com.br/api/municipios');
          this.dataMunicipios = response2.data.municipios;
          this.dataMunicipiosArray = Object.values(this.dataMunicipios);
          window.dataMunicipiosArrayKeys = Object.keys(this.dataMunicipios);
      }catch(e){
          // console.log('error');
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      // console.log('entire view has been rendered');
      this.fullURL = window.location.origin;
      this.getDestaques();
      this.t();
      this.getLinks();
      this.getUltimasNoticias();
      this.getDadosMunicipios();
    })
  }
}
</script>

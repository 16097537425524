<template>
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-6">
            <div class="row">

              <div class="col-lg-7 col-md-6">
                <iframe v-bind:src="dataParametros.link_google_maps" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>
              </div>

              <div class="col-lg-5 col-md-6">
                <div>
                  <i class="icofont-google-map"></i>
                  <h4>Endereco:</h4>
                  <div>{{ dataParametros.endereco }}</div>

                  <br />
                  
                  <i class="icofont-phone"></i>
                  <h4>Telefone:</h4>
                  <div>{{ dataParametros.telefone }}</div>

                  <br />

                  <i class="icofont-envelope"></i>
                  <h4>Email:</h4>
                  <div>{{ dataParametros.email }}</div>

                </div>
              </div>
            </div>

        </div>
        <div class="col-lg-3 col-md-6">
          <a href="/">
            <img v-bind:src="dataParametros.url_logo" v-bind:alt="dataParametros.descricao" class="img-fluid">
          </a>
          <h3>{{ dataParametros.descricao }}</h3>
          <p v-if="dataParametros.horario_atendimento">Atendimento: {{ dataParametros.horario_atendimento }}</p>
          
          <AppSocialLinks
            :whatsapp="dataParametros.link_whatsapp"
            :twitter="dataParametros.link_twitter"
            :facebook="dataParametros.link_facebook"
            :instagram="dataParametros.link_instagram"
            :skype="dataParametros.link_skype"
            :linkedin="dataParametros.link_linkedin"
          />

          <div class="copyright">
            2021 - <strong><span>{{ dataParametros.descricao }}</span></strong>.
          </div>
          <div class="credits">
            Designed by <a href="https://cespro.com.br/">CESPRO</a>
          </div>

        </div>
      </div>
    </div>
  </footer><!-- End Footer -->
</template>

<script>

import axios from 'axios';
import AppSocialLinks from './AppSocialLinks';

export default {
  components: {
    AppSocialLinks
  },
  data(){
    return {
      dataParametros: {},
    }
  },
  methods: {
    async getDadosParametro(){
      try{
          let response2 = await axios.get('https://preview.tmpcms.cespro.com.br/api/parametros');
          this.dataParametros = response2.data.parametros[0];
      }catch(e){
          // console.log('error');
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      // // console.log("entire view has been rendered app header");
      this.getDadosParametro();
    });
  }
};
</script>
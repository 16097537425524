<template>
  <div class="social-links">
    <a v-if="whatsapp" target="_blank" v-bind:href="whatsapp" class="whatsapp"><i class="icofont-brand-whatsapp"></i></a>
    <a v-if="twitter" target="_blank" v-bind:href="twitter" class="twitter"><i class="icofont-twitter"></i></a>
    <a v-if="facebook" target="_blank" v-bind:href="facebook" class="facebook"><i class="icofont-facebook"></i></a>
    <a v-if="instagram" target="_blank" v-bind:href="instagram" class="instagram"><i class="icofont-instagram"></i></a>
    <a v-if="skype" target="_blank" v-bind:href="skype" class="skype"><i class="icofont-skype"></i></a>
    <a v-if="linkedin" target="_blank" v-bind:href="linkedin" class="linkedin"><i class="icofont-linkedin"></i></a>
  </div>
</template>

<script>
  export default {
    props: [
      'twitter', 'facebook', 'instagram', 'skype', 'linkedin', 'whatsapp'
    ]
  }
</script>
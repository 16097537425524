import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Page1 from '../views/Page1.vue'
import Page2 from '../views/Page2.vue'

import NotFound from '../views/NotFound.vue';
import Noticia from '../views/Noticia.vue';
import Pagina from '../views/Pagina.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/page1',
    name: 'Page1',
    component: Page1
  },
  {
    path: '/page2',
    name: 'Page2',
    component: Page2
  },
  {
    path: '/noticias/:noticiaAtual',
    name: 'Noticias',
    component: Noticia,
  },
  {
    path: '/noticias/',
    name: 'NoticiasNav',
    component: Noticia,
  },
  {
    path: '/pagina/:paginaAtual',
    name: 'Pagina',
    component: Pagina,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

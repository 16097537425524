<template>

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <h2></h2>
          <ol>
            <li><a href="/">Home</a></li>
            <li>{{ dataPagina.titulo }}</li>
          </ol>
        </div>
      </div>
    </section>
    <!-- End Breadcrumbs -->

    <section class="inner-page">
      <div class="container">
        <div class="section-title">
          <h2>{{ dataPagina.titulo }}</h2>
          <div v-html="dataPagina.conteudo" class="text-justify"></div>
        </div>
      </div>
    </section>

  </main>
  <!-- End #main -->

</template>


<script>
import axios from 'axios';
export default {
  data(){
    return{
      dataPagina: []
    }
  },
  components: {},
  methods: {
    t() {
      window.geral();
    },
    async getPagina(dsPagina){
      try{
          let response2 = await axios.get("https://preview.tmpcms.cespro.com.br/api/paginas/show/"+dsPagina);
          this.dataPagina = response2.data.pagina;
      }catch(e){
          // console.log('error');
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      // console.log("entire view has been rendered... pagina");
      this.t();
      this.getPagina(this.$route.params.paginaAtual);
    });
  },
};
</script>


<template>
  <!-- ======= Top Bar ======= -->
  <div id="topbar" class="d-none d-lg-flex align-items-center fixed-top">
    <div class="container d-flex">
      <div class="contact-info mr-auto">
        <i class="icofont-envelope"></i>
        <a href="#">{{ dataParametros.email }}</a>
        <i class="icofont-phone"></i> {{ dataParametros.telefone }}
      </div>

      <AppSocialLinks
        :whatsapp="dataParametros.link_whatsapp"
        :twitter="dataParametros.link_twitter"
        :facebook="dataParametros.link_facebook"
        :instagram="dataParametros.link_instagram"
        :skype="dataParametros.link_skype"
        :linkedin="dataParametros.link_linkedin"
      />
      
    </div>
  </div>

  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center">

      <a href="/" class="logo">
        <img v-bind:src="dataParametros.url_logo" v-bind:alt="dataParametros.descricao" class="img-fluid">
      </a>
      <h1 class="logo mr-auto">&nbsp;<a href="/">{{ dataParametros.descricao }}</a></h1>

      <nav class="nav-menu d-none d-lg-block">
        <ul>
          <li v-for="menu in dataMenus" :key="menu.id"
            v-bind:class="menu.sub!='' ?'drop-down':''">
            <a v-bind:href="menu.url">{{menu.descricao}}</a>

            <ul v-if="menu.sub">
              <li v-for="menu_sub in menu.sub" :key="menu_sub.id">
                <a v-bind:href="menu_sub.url" v-bind:target="menu_sub.url.indexOf('://')>0?'_blank':''">{{menu_sub.descricao}}</a>
              </li>
            </ul>

          </li>
          
          <!-- <li><router-link :to="{ name: 'Home' }">Home</router-link></li>
          <li class="drop-down">
            <a href="#">A Cidade</a>
            <ul>
              <li><a href="/pagina/historico">Historico</a></li>
              <li><a href="/pagina/pagina-2">Pagina 2</a></li>
              <li><a href="/pagina/pagina-3">Pagina 3</a></li>
              <li><a href="/pagina/pagina-4">Pagina 4</a></li>
            </ul>
          </li>
          <li>
            <router-link :to="{ name: 'NoticiasNav' }">Noticias</router-link>
          </li> -->
          <!--
          <li><router-link :to="{name: 'Home'}">Pautas</router-link></li>
          <li><router-link :to="{name: 'Home'}">Diário Oficial</router-link></li>
          <li><router-link :to="{name: 'Home'}">Licitações</router-link></li>     
          
          <li class="active"><a href="/">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#portfolio">Portfolio</a></li>
          <li><a href="#team">Team</a></li>
          <li class="drop-down"><a href="">Drop Down</a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li class="drop-down"><a href="#">Deep Drop Down</a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li>
          <li><a href="#contact">Contact</a></li>
          -->
        </ul>
      </nav>
      <!-- .nav-menu -->
    </div>
  </header>
  <!-- End Header -->
</template>

<script>

import AppSocialLinks from './AppSocialLinks';
import axios from 'axios';

export default {
  components: {
    AppSocialLinks
  },
  data(){
    return {
      dataMenus: {},
      dataParametros: {},
    }
  },
  methods: {
    async getMenu(){
      try{
          let response2 = await axios.get('https://preview.tmpcms.cespro.com.br/api/menus');
          this.dataMenus = response2.data.menus;

          setTimeout(function(){
            window.makeMobileNavigation();
          }, 200);
      }catch(e){
          // console.log('error');
      }
    },
    async getDadosParametro(){
      try{
          let response2 = await axios.get('https://preview.tmpcms.cespro.com.br/api/parametros');
          this.dataParametros = response2.data.parametros[0];

          document.querySelector('head title').text = this.dataParametros.titulo;

          document.documentElement.style
                .setProperty('--app-default-color-primary:', this.dataParametros.cor_primary);
          document.documentElement.style
                .setProperty('--app-default-color-primary-alpha', this.dataParametros.cor_primary_alpha);
          document.documentElement.style
                .setProperty('--app-default-color-primary-hover', this.dataParametros.cor_primary_hover);
          document.documentElement.style
                .setProperty('--app-default-color-bg-footer', this.dataParametros.cor_bg_footer);
                
      }catch(e){
          // console.log('error');
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      // console.log("entire view has been rendered app header");
      this.getDadosParametro();
      this.getMenu();
    });
  },
};
</script>
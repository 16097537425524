<template>

  <AppHeader />

  <router-view />

  <AppFooter />
  <a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>
</template>

<script>

import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";

export default {
  components: {
    AppHeader,
    AppFooter
  },
  methods: {
    t(){
      // window.geral();
    }
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      // console.log('entire view has been rendered');
      this.t();
    })
  }
};
</script>
